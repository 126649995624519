<ion-content>
  <section>
    <div class="content">
      <svg class="icon">
        <path class="icon__pin" d="M64 32c0 22-32 56-32 56S0 54 0 32C0 14 14 0 32 0s32 14 32 32z" />
        <path
          class="icon__logo"
          d="M58 32C58 17.64 46.36 6 32 6S6 17.64 6 32s11.64 26 26 26 26-11.64 26-26zM30.6 45.028v-12.22L20.016 26.7a4.404 4.404 0 0 1-5.115.512 4.4 4.4 0 1 1 4.401-7.62 4.401 4.401 0 0 1 2.114 4.683L32 30.384l10.584-6.11a4.401 4.401 0 1 1 6.515 2.937 4.404 4.404 0 0 1-5.115-.512L33.4 32.81v12.219a4.402 4.402 0 1 1-2.8 0z"
        />
      </svg>
      <div class="text" *ngIf="(orders$ | async)?.length">
        <h1 class="text__heading">{{serviceName}} Scheduled!</h1>
        <p class="text__caption">
          Thanks for scheduling {{indefiniteArticleServiceName}}. We’ll send you a confirmation SMS shortly, with
          additional instructions.
        </p>
      </div>
      <div class="text" *ngIf="!(orders$ | async)?.length">
        <h1 class="text__heading">No {{serviceName}} Scheduled!</h1>
        <p class="text__caption">Tap below to schedule {{serviceName}}.</p>
      </div>
      <button
        ysh-button-block
        primary
        (click)="didTapOrder()"
        *ngIf="!(orderService.orders$ | async)?.length"
      >
        Order Now
      </button>
      <div class="card" *ngFor="let order of (orderService.orders$ | async)">
        <h2 class="card__heading">Appointment Details</h2>
        <div class="card__details">
          <div>{{order.date | date: 'fullDate'}}</div>
          <div>{{order.vehicle.description}}</div>
        </div>
        <div class="card__buttons">
          <button (click)="didTapCancel(order)">Cancel</button>
        </div>
      </div>
      <button
        ysh-button-block
        primary
        (click)="didTapBackToUber()"
        [disabled]="loadingBackToUber"
        *ngIf="this.uberService.appIsEmbeddedInUber && (orderService.orders$ | async)?.length"
      >
        <ng-container *ngIf="!loadingBackToUber"> Back to Uber </ng-container>
        <ion-spinner *ngIf="loadingBackToUber" name="dots" color="white"></ion-spinner>
      </button>
    </div>
    <div class="base">
      Have Questions? Need to schedule more appointments?
      <a href="mailto:support@startyoshi.com?subject=Service Order Help">Contact Support</a>
    </div>
  </section>
</ion-content>
